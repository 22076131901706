<template>
  <div class="container-fluid">
    <div class="card border-0 shadow-none">
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md mb-4">
            <h3 class="fw-bold text-success mb-0">Enquiries by Traffic Source</h3>
          </div>
          <div class="col-auto mb-4 ms-auto">
            <date-picker
              ref="datePicker"
              v-model="range"
              is-range
              :popover="{ visibility: 'click', transition: 'fade' }"
              :input-props="{ class: 'form-control form-control-sm' }"
            >
              <template v-slot="{ inputValue, togglePopover }">
                <div class="date-picker-wrap">
                  <input
                    :value="inputValue.start + ' - ' + inputValue.end"
                    v-on:click="togglePopover"
                    class="d-inline form-control disabled"
                    placeholder="End Date"
                    readonly
                  />
                </div> </template
            ></date-picker>
          </div>
        </div>

        <div v-if="!busy" class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Source</th>
                <th scope="col">Campaign</th>
                <th scope="col">Enquiries</th>
                <th scope="col">Bookings</th>
                <th scope="col">Conv %</th>
                <th scope="col">Value</th>
              </tr>
            </thead>
            <tbody v-for="(source, sIndex) in sources" :key="sIndex">
              <tr class="label" @click="toggleSource(source)">
                <td colspan="2" style="text-transform: capitalize;">
                  {{ source | formatString }}
                </td>
                <td>{{ fullData[source].total_enquiries }}</td>
                <td>{{ fullData[source].total_bookings }}</td>
                <td>
                  {{
                    calculateConversionPercentage(
                      fullData[source].total_bookings,
                      fullData[source].total_enquiries
                    )
                  }}
                </td>
                <td>{{ fullData[source].total_booking_value }}</td>
              </tr>
              <tr v-for="(e, eIndex) in enquiries" :key="eIndex" class="hidden" :class="source">
                <td v-if="source == e.source" style="text-transform: capitalize;">
                  <!-- {{ e.source | formatString }} -->
                </td>
                <td v-if="source == e.source" style="text-transform: capitalize;">
                  {{ e.name ? e.name : 'None' | formatString }}
                </td>
                <td v-if="source == e.source">
                  {{ e.enquiries }}
                </td>
                <td v-if="source == e.source">
                  {{ e.bookings }}
                </td>
                <td v-if="source == e.source">
                  {{ e.conversion_perc }}
                </td>
                <td v-if="source == e.source">
                  {{ e.booking_value }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <loading v-else class="mb-4"></loading>

      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/loaders/Loading";
import DatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
  data() {
    return {
      busy: false,
      enquiries: [],
      fullData: [],
      popover: {
        visibility: "focus"
      },
      range: {
        start: this.$route.query.start
          ? this.$route.query.start
          : moment().subtract(1, 'months').format("YYYY-MM-DD"),
        end: this.$route.query.end
          ? this.$route.query.end
          : new Date()
      },
      sources: []
    };
  },
  watch: {
    range: {
      handler: function(val, old) {
        this.fetchEnquiryData();
      }
    }
  },
  methods: {
    open() {
      this.$refs["datePicker"].$el.querySelector("input").focus();
    },
    calculateConversionPercentage(bookings, enquiries) {
      bookings = parseInt(bookings);
      enquiries = parseInt(enquiries);

      if (bookings == 0 && enquiries == 0) {
        return 0;
      }

      return ((bookings/enquiries)*100).toFixed(0);
    },
    fetchEnquiryData() {
      this.enquiries = [];
      this.busy = true;

      this.$axios(
        process.env.VUE_APP_API_URL +
          "/reporting/enquiries-by-traffic-source/new?start=" +
          moment(this.range.start).format("YYYY-MM-DD") +
          "&end=" +
          moment(this.range.end).format("YYYY-MM-DD")
      )
        .then(({ data }) => {
          this.fullData = data;

          for (const [key, value] of Object.entries(data)) {
            if (!this.sources.includes(key)) {
              this.sources.push(key);
            }

            for (const [k, c] of Object.entries(value.campaigns)) {
              let campaign = {
                source: key,
                name: c.name,
                enquiries: c.enquiries,
                bookings: c.bookings,
                conversion_perc: this.calculateConversionPercentage(c.bookings, c.enquiries),
                booking_value: c.booking_value
              }

              this.enquiries.push(campaign);
            };
          }
        })
        .finally(() => {
         this.busy = false;
        });
    },
    toggleSource(source) {
      document.querySelectorAll(`.${source}`).forEach((ele) => {
        ele.classList.toggle("hidden");
      });
    }
  },
  mounted() {
    this.$store.sidebarOpen = false;
    this.fetchEnquiryData();
  },
  filters: {
    formatString(string) {
      return string.replace(/_/g, " ");
    },
  },
  components: {
    DatePicker,
    Loading
  }
};
</script>

<style scoped>
th, td {
  width: 20% !important;
}

.label {
  background-color: #F2F2F2;
  color: #026838;
}

.hidden {
  display: none;
}
</style>